import { extendTheme, theme as baseTheme } from '@chakra-ui/react'
import { ChakraTheme } from '@chakra-ui/theme'
import { ThemeOverride } from '@chakra-ui/react'

export const themeExtension: ThemeOverride<ChakraTheme> = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: `'Playfair Display', serif`,
    body: `'Lora', serif`,
  },
  styles: {
    global: {
      'html, body, #___gatsby, #gatsby-focus-wrapper': {
        // height: '100%',
        margin: 0,
        overflowX: 'auto',
      },
    },
  },
}

export const retroTheme = extendTheme(
  themeExtension,
  baseTheme as ChakraTheme,
) as ChakraTheme
