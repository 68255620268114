import '../styles/typography.css'
import '../styles/nprogress.css'
import type { AppProps } from 'next/app'
import { ChakraProvider } from '@chakra-ui/react'
import * as React from 'react'
import Router from 'next/router'
import NProgress from 'nprogress'
import { retroTheme } from '@marco/frontend/theme'
import * as amplitude from '@amplitude/analytics-browser'
import { isBrowser } from '@marco/frontend/helpers/isBrowser'

if (isBrowser) {
  amplitude.init('b0c47ed2fefcb2e1d8ee33fa076129b1')
  const trackPageView = () => {
    amplitude.track('pageView', {
      project: 'marco',
      page: window.location.pathname,
    })
  }
  Router.events.on('routeChangeComplete', trackPageView)
  trackPageView()
}

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', NProgress.start)
Router.events.on('routeChangeComplete', NProgress.done)
Router.events.on('routeChangeError', NProgress.done)

function App({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={retroTheme}>
      <Component {...pageProps} />
    </ChakraProvider>
  )
}

export default App
